import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'
import wmAlert from '../helpers/wmAlerts'

export default defineComponent({
    computed: {
        ...mapGetters({
            hasPlan: 'user/hasPlan',
            isAdmin: 'user/isAdmin',
            isWorksheet: 'document/isWorksheet',
        }),
        ...mapState(['user', 'premium', 'document']),
        showSinglePrice() {
            if (!this.isEditor) return false

            if (!this.document.user_id) return true

            return !this.hasUserPurchasedThisDocument(this.document) && this.document.user_id == this.user.user.id
        },
    },
    methods: {
        async editPaywall(document, handler) {
            localStorage.setItem(
                'DocumentZoom',
                JSON.stringify({
                    id: this.document.id,
                    zoom: this.document.zoom,
                }),
            )
            const entity = document.entity_type
            const singlePurchaseEdits = window.localStorage.getItem('single_purchase_edits')
            const singlePurchases = singlePurchaseEdits ? JSON.parse(singlePurchaseEdits) : []
            const documentHasBeenEdited = singlePurchases.includes(document.id) || document.single_purchase_edits > 0
            const userHasPremiumFeatures = await this.$store.dispatch('document/getPremiumFeaturesByDocument', document)

            if (
                this.isAdmin ||
                (this.hasFeature('can-edit', document, document.entity_type) && !this.hasUserPurchasedThisDocument(document))
            ) {
                const userResponse = await wmAlert.info({
                    html: `<div class='mx-2 text-center'>Are you sure you want to unpublish this ${entity}?</div>`,
                })

                if (userResponse.isConfirmed) {
                    handler(document)
                }

                return
            }

            if (userHasPremiumFeatures.length || this.hasUserPurchasedThisDocument(document)) {
                if (!documentHasBeenEdited) {
                    const userResponse = await wmAlert.confirm({
                        title: 'Unpublish & Edit?',
                        html: `<div class='text-center'>Are you sure you want to unpublish and change this ${entity}?
                                        <div class='text-danger font-weight-bold'>You can edit this document only once.</div>
                                    </div>`,
                    })

                    if (userResponse.isConfirmed && handler) {
                        singlePurchases.push(document.id)
                        localStorage.setItem('single_purchase_edits', JSON.stringify(singlePurchases))
                        handler(document)
                    }

                    return
                }
            }

            const userResponse = await wmAlert.confirm({
                title: 'Edit unavailable. Create Copy?',
                html: `This document has reached the maximum allowed edits and cannot be edited again. To make changes, create a copy below.`,
            })

            if (userResponse.isConfirmed) {
                window.location.replace(`/worksheet/${document.id}/copy`)
            }
        },
        hasUserPurchasedThisDocument(document) {
            return (
                document?.user_has_purchased_document ||
                this.user.user.documents_purchased?.some((doc) => {
                    return doc.id === document.id
                })
            )
        },
        hasFeature(feature, document, docType) {
            if (
                this.user.user.is_admin ||
                ['Gratis', 'Lifetime', 'Premium', 'Free Trial'].some((item) => item === this.user.user.subscription_plan)
            ) {
                return true
            }

            if (feature === 'full-access') {
                if (docType === 'crossword' || docType === 'wordsearch') return false
                return !(
                    (docType == 'worksheet' && this.user.user.subscription_plan !== 'Standard') ||
                    (docType == 'bingo' && !this.user.user.subscription_plan.includes('Bingo')) ||
                    (docType == 'flashcard' && !this.user.user.subscription_plan.includes('Flashcard'))
                )
            }

            if (feature === 'organize-folder') {
                return this.user.user.subscription_plan !== 'Free' && this.user.user.subscription_plan !== 'Basic'
            }

            if (feature === 'private') {
                return (
                    !this.isWorksheet ||
                    this.user.user.subscription_plan !== 'Free' ||
                    this.hasUserPurchasedThisDocument(document)
                )
            }

            if (feature === 'formatting') {
                return (
                    this.hasUserPurchasedThisDocument(document) ||
                    !(
                        (docType == 'worksheet' && this.user.user.subscription_plan !== 'Standard') ||
                        (docType == 'bingo' && !this.user.user.subscription_plan.includes('Bingo')) ||
                        (docType == 'flashcard' && !this.user.user.subscription_plan.includes('Flashcard'))
                    )
                )
            }

            if (feature === 'image') {
                return (
                    this.hasUserPurchasedThisDocument(document) ||
                    !(
                        (docType == 'worksheet' && this.user.user.subscription_plan !== 'Standard') ||
                        (docType == 'bingo' && !this.user.user.subscription_plan.includes('Bingo')) ||
                        (docType == 'flashcard' && !this.user.user.subscription_plan.includes('Flashcard'))
                    )
                )
            }

            if (feature === 'remove-logo') {
                return (
                    this.hasUserPurchasedThisDocument(document) ||
                    (this.user.user.subscription_plan !== 'Free' && this.user.user.subscription_plan !== 'Basic')
                )
            }

            if (feature === 'unpublish-and-edit') {
                let hasEdited = document.single_purchase_edits > 0
                let singlePurchases = []
                const singlePurchaseEdits = window.localStorage.getItem('single_purchase_edits')
                if (singlePurchaseEdits) {
                    try {
                        singlePurchases = JSON.parse(singlePurchaseEdits)
                        hasEdited = singlePurchases.includes(document.id) || document.single_purchase_edits > 0
                    } catch (e) {}
                }
                return !(this.hasUserPurchasedThisDocument(document) && hasEdited)
            }

            if (feature === 'can-edit') {
                return (
                    (docType == 'worksheet' && ['Standard', 'Basic'].includes(this.user.user.subscription_plan)) ||
                    (docType == 'bingo' && this.user.user.subscription_plan.includes('Bingo')) ||
                    (docType == 'flashcard' && this.user.user.subscription_plan.includes('Flashcard'))
                )
            }
        },
    },
})
